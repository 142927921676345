<template>
    <div id="information-lookup">
      <div class="lookup">
        <div class="logo">
          <img src="../assets/logo.png" alt="">
        </div>
        <div class="label">Tra cứu phiếu thông tin thu nhập</div>
        <form @submit.prevent="handleSubmit">
          <DxForm  :form-data="formData" label-mode="floating" :show-colon-after-label="true" ref="employeeInfo">
                <!-- <DxItem data-field="name" :editor-options="{height: 50, placeholder: 'Viết hoa chữ cái đầu'}">
                    <DxLabel text="Họ và tên nhân viên" />
                    <DxRequiredRule message="Vui lòng nhập tên nhân viên" />
                </DxItem> -->
                <DxItem data-field="employeeCode" :editor-options="{height: 50}">
                    <DxLabel text="Mã nhân viên" />
                    <DxRequiredRule message="Vui lòng nhập mã nhân viên" />
                </DxItem>
                <!-- <DxItem 
                    data-field="birth" 
                    editor-type="dxDateBox" 
                    :editor-options="{
                        displayFormat: 'dd/MM/yyyy', 
                        invalidDateMessage: 'Ngày sinh không hợp lệ',
                        height: 50,
                    }"
                >
                    <DxLabel text="Ngày sinh" />
                    <DxRequiredRule message="Vui lòng chọn ngày sinh" />
                </DxItem> -->
                <DxButtonItem :button-options="buttonOptions"/>
          </DxForm>
        </form>
            <DxLoadPanel
            :visible="loading"
            :show-indicator="true"
            :show-pane="true"
            :shading="true"
            shading-color="rgba(0,0,0,0.4)"
          />
      </div>
    </div>
  </template>
  
  <script>
  import {
      DxForm,
      DxItem,
      DxLabel,
      DxButtonItem,
      DxRequiredRule,
  } from 'devextreme-vue/form';
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
  import notify from 'devextreme/ui/notify';
  import axios from 'axios';

  const API_URL = process.env.VUE_APP_API_URL;
  export default {
    components: {
      DxForm,
      DxItem,
      DxLabel,
      DxButtonItem,
      DxRequiredRule,
      DxLoadPanel
    },
    data() {
      return {
        formData: {},
        loading: false,
        message: '',
        selected: false,
        buttonOptions: {
            text: 'Tra cứu thông tin',
            useSubmitBehavior: true,
            width: '100%',
        },
      }
    },
    computed: {
      form() {
            return this.$refs.employeeInfo.instance;
        },
    },
    methods: {
      defaultFormData() {
          this.formData = {
              employeeCode: '',
          }
      },
      handleSubmit() {
        this.login()
      },
      async login() {
        let birthDate = new Date(this.formData.birth)
        birthDate = `${birthDate.getDate() > 9 ? birthDate.getDate(): '0'+birthDate.getDate()}/0${birthDate.getMonth() + 1}/${birthDate.getFullYear()}`
        console.log(birthDate)
        let fd = new FormData();
        fd.append('name', this.formData.name)
        fd.append('employeeCode', this.formData.employeeCode);
        fd.append('birth', birthDate);
        this.loading = true
        await axios.post(`${API_URL}api/salary/new-request`, fd).then((res) => {
          let success = res.data.success
          console.log(res)
          if(!success) {
            this.loading = false
            this.message = 'Thông tin không hợp lệ'
            this.show('error')
          } else {
            this.loading = false
            this.$router.push(`/salary?mnv=${this.formData.employeeCode}`).catch(err => {
              if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
              ) {
                logError(err);
              }
            })
          }
        }).catch((err) => {
          this.loading = false
          this.message = 'Đã có lỗi xảy ra'
          this.show('error')
        })
      },
      resetData() {
          this.defaultFormData()
      },
      show(type) {
          notify({
              message: `${this.message}`,
              height: 45,
              maxWidth: 350,
              type: `${type}`,
              displayTime: 1500,
              animation: {
              show: {
                  type: 'fade', duration: 100, from: 0, to: 1,
              },
              hide: { type: 'fade', duration: 40, to: 0 },
              },
          },
          { position: 'top center'}
          );
      },
    },
  }
  </script>
  
  <style lang="scss">
  #information-lookup {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lookup {
      max-width: 600px;
      width: 70%;
      padding: 40px;
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 	#C0C0C0;
      .logo {
        text-align: center;
        margin-bottom: 30px;
          img {
            height: 30px;
          }
      }
      .label {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        padding-bottom: 20px;
      }
      .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text {
          background-color: #034ab3;
          box-shadow: 0 10px #053986;
          border: none;
          color: white;
          font-size: 22px;
          font-weight: 600;
          margin-top: 10px;
          &:hover {
                background-color: #0655CA;
            }
          &:active {
              transform: translateY(10px);
              box-shadow: 0 0 black;
          }
      }
    }
  }
  </style>
  