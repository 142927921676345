<template>
    <div v-if="this.$route.query && this.$route.query.name" class="import">
        <div class="label">NHẬP BẢNG LƯƠNG</div>
        <DxFileUploader
        :accept="accept"
        :multiple="multiple"
        :upload-mode="uploadMode"
        upload-url="https://js.devexpress.com/Demos/NetCore/FileUploader/Upload"
        width="300px"
        @value-changed="e => file = e.value"
      />
      <div class="gr-btn">
          <button class="import-btn" type="button" @click="importData">Cập nhật</button>
          <button class="dx-icon-clock history-btn" type="button" @click="showHistory"></button>
      </div>
    <DxLoadPanel
        :visible="loading"
        :show-indicator="true"
        :show-pane="true"
        :shading="true"
        shading-color="rgba(0,0,0,0.4)"
    />
    <DxPopup
      :visible="popupVisible"
      @hidden="onHidden"
      :max-width="540"
      :height="400"
      :hide-on-outside-click="false"
      :show-close-button="true"
      title="Lịch sử cập nhật"
    >
    <template #content>
        <DxScrollView>
            <div class="update-list-item" v-for="(item, index) in updateList" :key="index">
                <div class="name">{{ item.name }}</div>
                <div class="description">{{ item.description }}</div>
                <div class="time">{{ item.time }}</div>
            </div>
        </DxScrollView>
    </template>
    </DxPopup>
    </div>
</template>

<script>
import axios from 'axios';
import { DxPopup } from 'devextreme-vue/popup';
import { DxScrollView } from 'devextreme-vue/scroll-view';
import { DxFileUploader } from 'devextreme-vue/file-uploader';
import { DxLoadPanel } from 'devextreme-vue/load-panel';
import notify from 'devextreme/ui/notify';
const API_URL = process.env.VUE_APP_API_URL;
export default {
    components: {
        DxFileUploader,
        DxLoadPanel,
        DxPopup,
        DxScrollView
    },
    data() {
        return {
            multiple: false,
            accept: '*',
            uploadMode: 'instantly',
            fileTypesSource: [
                { name: 'All types', value: '*' },
                { name: 'Images', value: 'image/*' },
                { name: 'Videos', value: 'video/*' },
            ],
            userName: null,
            file: null,
            message: '',
            loading: false,
            popupVisible: false,
            updateList: []
        };
    },

    mounted() {
        this.userName = this.$route.query.name
    },

    methods: {
        async importData() {
            let fd = new FormData()
            if(this.file === null) {
                this.message = 'Vui lòng upload file'
                this.show('error')
                return
            }
            fd.append('name', this.userName)
            fd.append('file', this.file[0]);
            this.loading = true
            await axios.post(`${API_URL}api/salary/import`, fd, { headers: {
                "Content-Type": "multipart/form-data",
            }}).then((res) => {
                console.log(res.data)
                if(res) {
                    this.loading = false
                    this.message = 'Đã cập nhật danh sách thành công'
                    this.show('success')
                }
                setTimeout(() => {
                    location.reload()
                }, 1000);
            }).catch((err) => {
                this.loading = false
                this.message = 'Đã có lỗi xảy ra'
                this.show('error')
            })
        },
        async showHistory() {
            this.loading = true
            await axios.get(`${API_URL}api/history-logs/new-request`).then(res => {
                if(res) {
                    
                    this.updateList = res.data
                    this.loading = false
                    this.$forceUpdate()
                    this.popupVisible = true
                    console.log(this.updateList)
                }
            })
        },
        onHidden() {
            this.popupVisible = false
        },
        show(type) {
          notify({
              message: `${this.message}`,
              height: 45,
              maxWidth: 350,
              type: `${type}`,
              displayTime: 3500,
              animation: {
              show: {
                  type: 'fade', duration: 400, from: 0, to: 1,
              },
              hide: { type: 'fade', duration: 40, to: 0 },
              },
          },
          { position: 'top center'}
          );
      },
    },
};
</script>

<style lang="scss">
.import {
    background-color: #fff;
    box-shadow: 0px 0px 8px #C0C0C0;
    border-radius: 10px;
    width: 70%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    .label {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        padding-bottom: 20px;
      }
    .gr-btn {
        width: 100%;
        display: flex;
        justify-content: space-around;
        align-items: center;
        .import-btn {
            flex: 8;
            width: 100%;
            background-color: #034ab3;
            box-shadow: 0 10px #053986;
            padding-top: 8px;
            margin: 4px;
            border: none;
            border-radius: 4px;
            color: white;
            font-size: 20px;
            font-weight: 600;
            margin-top: 10px;
            cursor: pointer;
            &:hover {
                    background-color: #0655CA;
                }
              &:active {
                  transform: translateY(10px);
                  box-shadow: 0 0 black;
              }
        }
        .history-btn {
            flex: 1;
            width: 100%;
            background-color: #32CD32;
            box-shadow: 0 10px #228B22;
            padding-top: 6px;
            padding-bottom: 2px;
            margin: 4px;
            border: none;
            border-radius: 4px;
            color: white;
            font-size: 24px;
            font-weight: 100;
            margin-top: 10px;
            cursor: pointer;
            &:hover {
                    background-color: #21DF21;
                }
              &:active {
                  transform: translateY(10px);
                  box-shadow: 0 0 black;
              }
        }
    }
}
    .update-list-item {
        background-color:#ECECEC;
        border-radius: 4px;
        height: 40px;
        margin-bottom: 6px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 4px;
        .name {
            flex: 1;
            font-weight: 600;
        }
        .description {
            flex: 3;
        }
        .time {
            flex: 2;
        }
    }

</style>