import Vue from 'vue'

import Router from 'vue-router'
import Salary from '../components/Salary'
import InformationLookup from '../components/InformationLookup'
import HumanResoucesDepartment from '../components/HumanResoucesDepartment'
import ImportPage from '../components/ImportPage'
import Home from '../components/Home'

Vue.use(Router)

export default new Router({
  routes: [ 
    {
      path: '/',
      name: 'Home', 
      component: Home,
    },
    {
      path: '/salary',
      name: 'Salary',
      component: Salary,
    },
    {
      path: '/tra-cuu-thong-tin',
      name: 'Lookup', 
      component: InformationLookup,
    },
    {
      path: '/phong-nhan-su',
      name: 'HumanResoucesDepartment', 
      component: HumanResoucesDepartment,
    },
    {
      path: '/phong-nhan-su/import',
      name: 'ImportPage', 
      component: ImportPage,
    },
  ]
})