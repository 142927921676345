<template>
    <div id="salary">
       
        <div class="salary-list">
            <div class="logo">
                <img src="../assets/logo.png" alt="">
                <strong>{{ name }}</strong>
            </div>
            <div class="title">Danh sách
                <div ref="more" class="more" @click="showList"> <img src="../assets/arrow_down.png" alt=""></div>
            </div>
            <div ref="moreMenu" class="list">
                <div v-for="(item, index) in salaryList" :key="index" class="item" :class="{active: selectedPeriod == index}" @click="selectedSalaryPeriod(index)">Kỳ lương {{ item.brand }} tháng {{ item.salary_period }}</div>
            </div>
        </div>
        <div class="salary-detail">
            <div class="container">
                <div class="title">
                    <span>PHIẾU THÔNG TIN THU NHẬP</span>
                    <span>THÁNG {{ getData('salary_period') }}</span>
                </div>
                <div class="info">
                    <div>
                        <span >Họ và tên: <strong>{{ getData('name') }}</strong></span>
                    </div>
                    <div>
                        <span>Mã NV: {{ getData('employee_code') }}</span>
                        <span>Chức vụ: {{ getData('position') }}</span>
                    </div>
                </div>
                <div v-for="(item, index) in information" :key="index" class="info">
                    <div v-if="item.title.name !== null">
                        <span style="text-transform: uppercase;"><strong>{{ item.title.name }}</strong></span>
                        <span><strong>{{ getData(item.title.value) | money }}</strong></span>
                    </div>
                    <div v-for="(subtitle,index) in item.subtitles" :key="index">
                        <span><p style="display: inline-block; margin: 0;" v-if="item.title.name !== null">+</p> {{ subtitle.name }}</span>
                        <span v-if="subtitle.value == 'actual_hours' || subtitle.value == 'standard_hours'">{{ getData(subtitle.value) | hours }}</span>
                        <span v-else>{{ getData(subtitle.value) | money }}</span>
                    </div>
                </div>
                <div class="info" style="border: none;">
                    <div>
                        <span><strong>TỔNG THU NHẬP THỰC TẾ</strong></span>
                        <span>{{ getData('real_salary') | money }}</span>
                    </div>
                    <div>
                        <span>+ Chuyển khoản đợt 1</span>
                        <span>{{ getData('first_salary_transfer') | money }}</span>
                    </div>
                    <div>
                        <span>+ Chuyển khoản đợt 2</span>
                        <span>{{ getData('second_salary_transfer') | money }}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { cloneDeep } from 'lodash'
import axios from 'axios'

const API_URL = process.env.VUE_APP_API_URL;
export default {

    data() {
        return {
            name: '',
            loading: true,
            selectedPeriod: 0,
            salaryList: [],
            salaryItem: {},
            information: []
        };
    },
    computed: {
    },
    mounted() {
        this.defaultSalaryItem()
        this.getEmployeeCode()
    },

    methods: {
        defaultSalaryItem() {
            this.salaryItem = {                
                BHXH: null,
                actual_hours: null,
                bonus_salary: null,
                employee_code: null,
                first_salary_transfer: null,
                name: null,
                official_salary: null,
                other_deductions: null,
                other_support: null,
                position: null,
                probationary_salary: null,
                real_salary: null,
                second_salary_transfer: null,
                standard_hours: 1,
                start_day: null,
                subsidized_salary: null,
                total_deduction: null,
                total_salary: null,
                salary_period: null
            }
        },
        async getEmployeeCode() {
            if (this.$route.query && this.$route.query.mnv) {
                let employeeCode = this.$route.query.mnv
                await axios.get(`${API_URL}api/config-salary-details/1`).then((res) => {
                    this.information = res.data.data.attributes.information
                })
                await axios.post(`${API_URL}api/salary/get-list`, {employeeCode: employeeCode}).then((res) => {
                    console.log(res.data)
                    this.salaryList = res.data
                    this.selectedSalaryPeriod(0)
                    this.name = res.data[0].name
                })
            }
        },
        selectedSalaryPeriod(index) {
            this.selectedPeriod = index
            Object.keys(this.salaryItem).forEach(key => {
                this.salaryItem[key] = this.salaryList[index][key]
            })
        },
        showList() {
            let moreEl = this.$refs.more
            let moreMenuEl = this.$refs.moreMenu
            moreEl.classList.toggle('closed')
            moreMenuEl.classList.toggle('closed')
        },
        getData(value) {
            return this.salaryItem[value]? this.salaryItem[value] : "--------"
        }
    },

    filters: {
        money(value) {
            return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' đồng'
        },
        hours(value) {
            return value + ' giờ'
        }
    },
};
</script>

<style lang="scss">
#salary {
    min-height: 100vh;
    width: 100%;
    background-color: #EAEAEA;
    display: flex;
    flex-direction: row;
    .salary-list {
        flex: 1;
        min-height: 100vh;
        background-color: #034ab3;
        .logo {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px;
            background-color: #0040A1;
            color: #fff;
            img {
                max-width: 500px;
                width: 90%;
                margin-bottom: 20px;
            }
        }
        .title {
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            padding: 10px;
            .more {
                display: none;
            }
        }
        .list {
            height: 600px;
            overflow: scroll;
            .item {
                color: #fff;
                padding: 14px;
                transition: all .4s;
                &:hover {
                    background-color: #ffcc4f;
                    color: #000000;
                    font-weight: 600;
                    cursor: pointer;
                }
            }
            &::-webkit-scrollbar {
                display: none;
            }
            .active {
                background-color: #ffcc4f;
                color: #000000;
                font-weight: 600;
            }
        }
    }
    .salary-detail {
        flex: 3.5;
        display: flex;
        justify-content: center;
        align-items: center;
        .container {
            max-width: 1200px;
            width: 90%;
            background-color: #fff;
            border: 2px solid black;
            .title {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                background-color: #034ab3;
                border-bottom: 2px solid black;
                span {
                    font-size: 20px;
                    font-weight: 600;
                    padding: 10px;
                    color: #fff;
                }
            }
            .info {
                display: flex;
                flex-direction: column;
                width: 100%;
                border-bottom: 2px solid black;
                div {
                    color: #2f5496;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: flex-start;
                    width: 100%;
                    span {
                        padding: 10px 20px;
                    }
                }
            }
        }
    }
}
@media only screen and (max-width: 900px) {
    #salary {
        flex-direction: column;
        .salary-list {
            margin-bottom: 20px;
            min-height: 40px;
            .title {
                position: relative;
                .more {
                        display: block;
                        cursor: pointer;
                        position: absolute;
                        top: 0;
                        right: 10px;
                        height: 40px;
                        width: 40px;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        -webkit-transition: all 0.3s ease;
                        transition: all 0.3s ease;
                        img {
                            height: 60%;
                            width: 80%;
                        }
                }
                .closed {
                    transform: rotate(180deg);
                }
            }
            .list {
                height: 0;
                -webkit-transition: all 0.3s ease;
                transition: all 0.3s ease;
            }
            .closed {
                height: 200px;
            }
        }
    }
}
</style>