<template>
    <div class="home">
        <div class="choose-options">
            <div @click="chooseOptions('Nhân viên')">Nhân viên</div>
            <div @click="chooseOptions('Nhân sự')">Quản trị viên</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MonthlySalaryHome',

    data() {
        return {
            
        };
    },

    mounted() {
    },

    methods: {
        chooseOptions(value) {
            if( value === "Nhân sự") {
            this.$router.push('/phong-nhan-su')
            } else {
                this.$router.push('/tra-cuu-thong-tin')
            }
        },
    },
};
</script>

<style lang="scss">
.home {
    .choose-options {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        div {
            padding: 10px;
            margin: 10px;
            max-width: 200px;
            width: 200px;
            background-color: #034ab3;
            box-shadow: 0 10px #053986;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 10px;
            color: #fff;
            font-weight: 600;
            transition: hover 1s;
            cursor: pointer;
            &:hover {
                background-color: #0655CA;
            }
            &:active {
                transform: translateY(10px);
                box-shadow: 0 0 black;
            }
        }
    }
}
</style>