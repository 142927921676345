<template>
    <div id="information-lookup">
      <div class="lookup">
        <div class="logo">
          <img src="../assets/logo.png" alt="">
        </div>
        <div class="label">Đăng nhập</div>
        <form @submit.prevent="handleSubmit">
          <DxForm :form-data="formData" label-mode="outside" :show-colon-after-label="false" ref="admin">
              <DxItem data-field="account" :editor-options="{height: 40}">
                  <DxLabel text="Tài khoản"/>
                  <DxRequiredRule message="Vui lòng nhập tài khoản" />
              </DxItem>
              <DxItem 
                  data-field="password" 
                  :editor-options="{height: 40, mode: 'password'}"
              >
                  <DxLabel text="Mật khẩu" />
                  <DxRequiredRule message="Vui lòng nhập mật khẩu" />
              </DxItem>
              <DxButtonItem :button-options="buttonOptions"/>
          </DxForm>
        </form>
          <DxLoadPanel
            :visible="loading"
            :show-indicator="true"
            :show-pane="true"
            :shading="true"
            shading-color="rgba(0,0,0,0.4)"
          />
      </div>
    </div>
  </template>
  
  <script>
  import {
      DxForm,
      DxItem,
      DxLabel,
      DxButtonItem,
      DxRequiredRule,
  } from 'devextreme-vue/form';
  import { DxLoadPanel } from 'devextreme-vue/load-panel';
    import notify from 'devextreme/ui/notify';
    import axios from 'axios';
    const API_URL = process.env.VUE_APP_API_URL;
  export default {
    components: {
      DxForm,
      DxItem,
      DxLabel,
      DxButtonItem,
      DxRequiredRule,
      DxLoadPanel
    },
    data() {
      return {
        formData: {},
        loading: false,
        message: '',
        selected: false,
        buttonOptions: {
            text: 'Đăng nhập',
            useSubmitBehavior: true,
            width: '100%',
        },
        passwordMode: 'password',
        passwordButton: { 
            icon: 'isblank',
            type: 'default',
            onClick: () => {
            this.passwordMode = this.passwordMode === 'text' ? 'password' : 'text';
            },
      },
      }
    },
    mounted() {
    },
    computed: {
      form() {
            return this.$refs.admin.instance;
        },
    },
    methods: {
      defaultFormData() {
          this.formData = {
              account: '',
              password: '',
          }
      },
      handleSubmit() {
        this.login()
      },
      async login() {
        let userName = this.formData.account
        let validate = this.form.validate()
        if( !validate.isValid) {
          this.message = 'Vui lòng nhập thông tin'
          this.show('error')
          return
        }
        let fd = new FormData();
        fd.append('identifier', this.formData.account);
        fd.append('password', this.formData.password);
        this.loading = true
        await axios.post(`${API_URL}api/auth/local`, fd).then((res) => {
          if(res.status === 200) {
            this.loading = false
            this.message = 'Đăng nhập thành công'
            this.show('success')
            this.$router.push(`/phong-nhan-su/import?name=${userName}`).catch(err => {
              if (
                err.name !== 'NavigationDuplicated' &&
                !err.message.includes('Avoided redundant navigation to current location')
              ) {
                logError(err);
              }
            })
          }
        }).catch((err) => {
          this.loading = false
          this.message = 'Thông tin không chính xác'
          this.show('error')
        })
      },
      resetData() {
          this.defaultFormData()
      },
      show(type) {
          notify({
              message: `${this.message}`,
              height: 45,
              maxWidth: 350,
              type: `${type}`,
              displayTime: 3500,
              animation: {
              show: {
                  type: 'fade', duration: 400, from: 0, to: 1,
              },
              hide: { type: 'fade', duration: 40, to: 0 },
              },
          },
          { position: 'top center'}
          );
      },
    },
  }
  </script>
  
  <style lang="scss">
  #information-lookup {
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .lookup {
      max-width: 400px;
      width: 70%;
      padding: 40px;
      margin-top: 20px;
      background-color: #fff;
      border-radius: 10px;
      box-shadow: 0px 0px 10px 	#C0C0C0;
      .logo {
        text-align: center;
        margin-bottom: 30px;
          img {
            height: 30px;
          }
      }
      .label {
        text-align: center;
        font-size: 22px;
        font-weight: 700;
        padding-bottom: 20px;
      }
      .dx-field-label {
        width: 30%;
      }
      .dx-widget.dx-button.dx-button-mode-contained.dx-button-normal.dx-button-has-text {
          background-color: #034ab3;
          box-shadow: 0 10px #053986;
          border: none;
          color: white;
          font-size: 22px;
          font-weight: 600;
          margin-top: 10px;
          &:hover {
                background-color: #0655CA;
            }
          &:active {
              transform: translateY(10px);
              box-shadow: 0 0 black;
          }
      }
    }
  }
  </style>
  